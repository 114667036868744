import { isBrowser } from "../../helpers"

// eslint-disable-next-line
export default theme => {
  if (!isBrowser) return null
  return {
    account: {
      backgroundColor: '#F1ECE8',
      '& .title': {
        flex: '0 0 100%',
        margin: '30px 0'
      },
      '& .page': {
        flex: '0 0 100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        '& .tabs': {
          paddingLeft: 20,
          [theme.breakpoints.up('md')]: {
            flex: '0 0 33.333%',
            margin: 0,
            paddingTop: 12,
          },
          [theme.breakpoints.up('lg')]: {
            flex: '0 0 25%',
            paddingLeft: 12,
          },
          '& button': {
            display: 'inline-block',
            backgroundColor: 'transparent',
            border: 'none',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            padding: '12px 20px',
            fontSize: 16,
            letterSpacing: '0.032em',
            fontFamily: theme.bodyFont,
            color: theme.palette.primary.navy,
            fontWeight: 'bold',
            cursor: 'pointer',
            '&[data-active="true"]': {
              backgroundColor: 'white',
            },
            [theme.breakpoints.up('md')]: {
              display: 'block',
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 8,
              padding: '32px',
              width: '100%',
              textAlign: 'left',
            },
          }
        },
        '& .tab': {
          flex: '0 0 calc(100% - 24px)',
          margin: '0 12px 30px',
          backgroundColor: 'white',
          borderRadius: 8,
          padding: 24,
          position: 'relative',
          zIndex: 1,
          [theme.breakpoints.up('md')]: {
            flex: '0 0 calc(66.666% - 12px)',
            margin: '0 0 50px',
            padding: 32,
          },
          [theme.breakpoints.up('lg')]: {
            flex: '0 0 calc(75% - 12px)',
            padding: 40,
          },
          '& .details, & .orders': {
            height: 0,
            overflow: 'hidden',
            opacity: 0,
            transition: 'opacity .3s ease-in-out',
            '&[data-active="true"]': {
              height: 'auto',
              opacity: 1,
            },
            '& h3': {
              marginBottom: 32,
            },
            '& p': {
              marginBottom: 16,
              '& strong': {
                width: 100,
                display: 'inline-block',
              }
            },
            '& .order': {
              borderTop: '1px solid rgba(0,0,0,0.2)',
              marginBottom: 24,
              paddingTop: 24,
              '& ul': {
                '& li': {
                  color: theme.palette.primary.navy,
                }
              }
            }
          }
        }
      }
    }
  }
}
