const styles = theme => ({
  header: {
    display: "block",
    position: "relative",
    marginTop: 0,
    maxWidth: 1142,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: theme.headerFont,
    padding: "0 12px",
    fontWeight: "normal",
    color: theme.palette.primary.navy,
    "& *": {
      fontFamily: theme.headerFont,
      fontWeight: "inherit",
    },
    "&.MuiTypography-h1": {
      fontSize: 40,
      lineHeight: 1.25,
      marginBottom: 8,
      fontWeight: "bold",
      letterSpacing: "-0.024em",
      [theme.breakpoints.up("sm")]: {
        fontSize: 44,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 56,
      },
    },
    "&.MuiTypography-h2": {
      fontSize: 36,
      lineHeight: 1.33,
      marginBottom: 8,
      fontWeight: 500,
      letterSpacing: "-0.016em",
      [theme.breakpoints.up("lg")]: {
        fontSize: 48,
      },
    },
    "&.MuiTypography-h3": {
      fontSize: 28,
      lineHeight: 1.25,
      marginBottom: 12,
      fontWeight: "bold",
      letterSpacing: "-0.008em",
      [theme.breakpoints.up("lg")]: {
        fontSize: 32,
      },
    },
    "&.MuiTypography-h4": {
      fontSize: 22,
      lineHeight: 1.33,
      marginBottom: 12,
      fontWeight: "bold",
      letterSpacing: "0.008em",
      [theme.breakpoints.up("lg")]: {
        fontSize: 24,
      },
    },
    "&.MuiTypography-h5": {
      fontSize: 16,
      lineHeight: 1.66,
      marginBottom: 16,
      letterSpacing: "0.008em",
      fontWeight: "bold",
      fontFamily: theme.bodyFont,
      [theme.breakpoints.up("lg")]: {
        fontSize: 24,
      },
    },
    "&.MuiTypography-h6": {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: "bold",
      marginBottom: 16,
      letterSpacing: "0.008em",
      fontFamily: theme.bodyFont,
    },
    "& a": {
      textDecoration: "underline !important",
      color: theme.palette.tertiary.main,
      "&:hover, &:focus": {
        color: "#B3D57C",
      },
    },
    // Background colours
    '&:not([data-override-background="initial"])': { padding: 12 },
  },
})

export default styles
