import React from "react"
import Cookies from "js-cookie"
import { connect } from "react-redux"
import { navigate } from "gatsby-link"
import { withStyles } from "@material-ui/styles"
import { format } from "date-fns"

import Layout from "../../components/layout"
import styles from "./account.styles"

import { authenticate } from "../../services/shopify"
import { isBrowser } from "../../helpers"

import { CoreHeadingBlock } from "../../components/blocks/CoreHeadingBlock"
import Paragraph from "../../components/Paragraph"

class CustomerAccountPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: null,
      lastName: null,
      ready: false,
      activeTab: "details",
    }

    this.authenticateUser()
  }

  authenticateUser() {
    const token = Cookies.get("customerToken")

    if (!token) {
      return navigate("/customer/login")
    }

    const customerToken = JSON.parse(token)

    authenticate({ customerToken: customerToken.token })
      .then(res => {
        const { status, data } = res
        if (!status === 200 || !data.data.customer) {
          navigate("/customer/login")
          this.setState({ ready: true })
        }

        this.setState({
          firstName: data.data.customer.firstName,
          lastName: data.data.customer.lastName,
          email: data.data.customer.email,
          phone: data.data.customer.phone,
          orders: data.data.customer.orders,
          address: data.data.customer.defaultAddress,
          ready: true,
        })
      })
      .catch(err => {
        navigate("/customer/login")
        this.setState({ ready: false })
      })
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      address,
      orders = [],
      ready,
    } = this.state
    return (
      <div className={this.props.classes.account}>
        <div className="wrapper">
          <div className="title">
            <CoreHeadingBlock
              attributes={{
                textAlign: "left",
                anchor: "",
                className: "",
                content: "My Account",
                level: 1,
                textColor: "initial",
                backgroundColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
          </div>
          {!ready && <p style={{ padding: 12 }}>loading...</p>}
          {ready && (
            <div className="page">
              <div className="tabs">
                <button
                  onClick={() => this.setState({ activeTab: "details" })}
                  onKeyDown={() => this.setState({ activeTab: "details" })}
                  data-active={
                    this.state.activeTab === "details" ? true : false
                  }
                >
                  Your Details
                </button>
                <button
                  onClick={() => this.setState({ activeTab: "orders" })}
                  onKeyDown={() => this.setState({ activeTab: "orders" })}
                  data-active={this.state.activeTab === "orders" ? true : false}
                >
                  Your Orders
                </button>
              </div>
              <div class="tab">
                <div
                  className="details"
                  data-active={
                    this.state.activeTab === "details" ? true : false
                  }
                >
                  <CoreHeadingBlock
                    attributes={{
                      textAlign: "left",
                      anchor: "",
                      className: "",
                      content: "Your Details",
                      level: 3,
                      textColor: "initial",
                      backgroundColor: "",
                      __typename: "WpCoreHeadingBlockAttributes",
                    }}
                    innerBlocks={[]}
                  />
                  {firstName !== null && (
                    <Paragraph className={`18`}>
                      <strong>Name:</strong> {firstName}
                    </Paragraph>
                  )}
                  {lastName !== null && (
                    <Paragraph className={`18`}>
                      <strong>Surname:</strong> {lastName}
                    </Paragraph>
                  )}
                  {email !== null && (
                    <Paragraph className={`18`}>
                      <strong>Email:</strong> {email}
                    </Paragraph>
                  )}
                  {phone !== null && (
                    <Paragraph className={`18`}>
                      <strong>Phone:</strong> {phone}
                    </Paragraph>
                  )}
                  {address && (
                    <Paragraph className={`18`}>
                      <strong>Address:</strong>{" "}
                      {`${address["address1"]}, ${address["address2"]}, ${address["city"]}, ${address["country"]}, ${address["zip"]}`}
                    </Paragraph>
                  )}
                </div>
                <div
                  className="orders"
                  data-active={this.state.activeTab === "orders" ? true : false}
                >
                  <CoreHeadingBlock
                    attributes={{
                      textAlign: "left",
                      anchor: "",
                      className: "",
                      content: "Your Orders",
                      level: 3,
                      textColor: "initial",
                      backgroundColor: "",
                      __typename: "WpCoreHeadingBlockAttributes",
                    }}
                    innerBlocks={[]}
                  />
                  {orders.edges.map(order => {
                    return (
                      <div className="order">
                        <CoreHeadingBlock
                          attributes={{
                            textAlign: "left",
                            anchor: "",
                            className: "",
                            content: "Order number " + order.node.name,
                            level: 4,
                            textColor: "initial",
                            backgroundColor: "",
                            __typename: "WpCoreHeadingBlockAttributes",
                          }}
                          innerBlocks={[]}
                        />
                        <Paragraph className={`18`}>
                          Total price: &#163; {order.node.totalPrice}
                        </Paragraph>
                        <Paragraph className={`18`}>
                          Date:{" "}
                          {format(
                            new Date(order.node.processedAt),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </Paragraph>
                        <CoreHeadingBlock
                          attributes={{
                            textAlign: "left",
                            anchor: "",
                            className: "",
                            content: "Products",
                            level: 6,
                            textColor: "initial",
                            backgroundColor: "",
                            __typename: "WpCoreHeadingBlockAttributes",
                          }}
                          innerBlocks={[]}
                        />
                        <ul>
                          {order.node.lineItems.edges.map(item => {
                            return (
                              <li>
                                {item.node.title} x {item.node.quantity}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const ConnectedCustomerAccountPage = connect(null, null)(CustomerAccountPage)

const Page = () => {
  if (!isBrowser)
    return (
      <Layout meta={{}} title="Account" path={"/customer/account/"}>
        <p>Loading...</p>
      </Layout>
    )

  const StyledAccountPage = withStyles(styles)(ConnectedCustomerAccountPage)

  return (
    <Layout meta={{}} title="Account">
      <StyledAccountPage />
    </Layout>
  )
}

export default Page
